import { useEffect } from "react";
import { SliceZone, useSinglePrismicDocument, usePrismicDocumentByUID, useAllPrismicDocumentsByType } from "@prismicio/react";

import { components } from "../slices";
import { Layout } from "../components/Layout";
import { HomepageBanner } from "../components/HomepageBanner";
import { NotFound } from "./NotFound";

/**
 * Website homepage component
 */
export const HomePage = () => {
  const [home, homeState] = useSinglePrismicDocument("homepage");
  const [menu, menuState] = usePrismicDocumentByUID("menu", "navbar_menu");
  const [projects, projectsState] = useAllPrismicDocumentsByType("project_page");

  const notFound = homeState.state === "failed" || menuState.state === "failed" || projectsState.sate === "failed";

  useEffect(() => {
    if (homeState.state === "failed") {
      console.warn(
        "Homepage document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [homeState.state]);

  // Return the page if a document was retrieved from Prismic
  if (home && projects && menu) {
    // console.log(projects)
    let sortedProjects = projects.sort((a,b) => {
      return new Date(a.data.project_date) - new Date(b.data.project_date)
    })
    const sortedProjectsReverse = sortedProjects.reverse();
    const lastFourSortedProjectsReverse = [];

    for (let i=0; i<5; i++) {
      lastFourSortedProjectsReverse.push(sortedProjectsReverse[i]);
    } ;

    return (
      <Layout wrapperClass="homepage" menuDoc={menu}>
        <HomepageBanner latestProject={lastFourSortedProjectsReverse[0]} otherProjects={lastFourSortedProjectsReverse.slice(1,projects.length)} />
        <SliceZone slices={home.data.page_content} components={components} />
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
