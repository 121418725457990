import { FullWidthImage } from "./FullWidthImage";
import { ImageGallery } from "./ImageGallery";
import { ImageHighlight } from "./ImageHighlight";
import { ParalaxImage } from "./ParalaxImage";
import { Quote } from "./Quote";
import { TextSection } from "./TextSection";
import { Timeline } from "./Timeline";

export const components = {
  full_width_image: FullWidthImage,
  image_gallery: ImageGallery,
  image_highlight: ImageHighlight,
  quote: Quote,
  text_section: TextSection,
  paralax_image: ParalaxImage,
  timeline: Timeline
};
