import { PrismicRichText } from "@prismicio/react";

/**
 * Timeline slice component
 */
export const Timeline = ({ slice }) => {

    const timelineData = slice.items;

    const FormattedDate = ({date, dateFormat}) => {
        const UsableDate = new Date(date);
        const month = UsableDate.getMonth();
        const monthString = UsableDate.toLocaleString('en', { month: 'long' });
        const year = UsableDate.getFullYear();
        const quarter = Math.floor((month + 3) / 3);

        if (dateFormat === "period") {
            return (
                <time>
                    Q{quarter} {year}
                </time>
            );
        } else if (dateFormat === "month") {
            return (
                <time>
                    {monthString} {year}
                </time>
            );
        } else {
            return (
                <time>
                    {date}
                </time>
            );
        }
    };

    const TimelineItem = ({ data }) => (
        <div className="timeline-item">
            <div className="timeline-item-content">
                <FormattedDate date={data.project_date} dateFormat={data.display_date_as}/>
                <span className="tag">
                    {data.project_title[0].text}
                </span>
                <PrismicRichText field={data.project_text}></PrismicRichText>
                {data.link && (
                    <a
                        href={data.link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {data.link.text}
                    </a>
                )}
                <span className="circle" />
            </div>
        </div>
    );
    
    const Timeline = () =>
        timelineData.length > 0 && (
            <div className="timeline-container">
                {timelineData.map((data, idx) => (
                    <TimelineItem data={data} key={idx} />
                ))}
            </div>
        );


    return (
        <section className="future-projects-timeline">
            <Timeline />
      </section>
    );
};
