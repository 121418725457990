import { PrismicText, PrismicLink } from "@prismicio/react";
import { useState, useEffect } from "react";
import {
  usePrismicDocumentByUID
} from "@prismicio/react";

/**
 * Menu link component
 */
const MenuLink = ({ menuLink }) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleTouchStart = (e) => {
    setDropdownVisible(true);

    e.preventDefault();
  }

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  if (menuLink.dropdown_menu) {
    const [projectsDropdownMenu, projectsDropdownMenuState] = usePrismicDocumentByUID("menu", menuLink.dropdown_menu_id[0].text);

    const notFound = projectsDropdownMenuState.state === "failed";

    if (projectsDropdownMenu) {
      const projectsDropdownMenuItems = [];
      projectsDropdownMenu.data.menu_links.map((dropdownMenuLink) => {
        projectsDropdownMenuItems.push(dropdownMenuLink)
      })
      return (
        <li className="projects-dropdown-menu" onTouchStart={handleTouchStart} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <a><PrismicText field={menuLink.label} /></a>
          <div className={(isDropdownVisible) ? "projects-dropdown-menu-container show" : "projects-dropdown-menu-container"} >
            <DropdownMenu projectMenuItems={projectsDropdownMenuItems} /> 
          </div>
        </li>
      )
      } else {
        return (
          <li className="projects-dropdown-menu">
          <PrismicText field={menuLink.label} />
          </li>
        );
      }
  } else {
    return (
      <li>
        <PrismicLink field={menuLink.link}>
          <PrismicText field={menuLink.label} />
        </PrismicLink>
      </li>
    );
  }
};

const DropdownMenu = ({projectMenuItems}) => {
  return (
    <ul>
      {projectMenuItems.map((menuLink, index, projectMenuItems) => (
        <ProjectsDropdown menuLink={menuLink} index={index} projectMenuItems={projectMenuItems} key={menuLink.link.id} />
      ))}
  </ul>
  );
};

const ProjectsDropdown = ({ menuLink, index, projectMenuItems }) => {
  if (index + 1 === projectMenuItems.length) {
    return (
      <li>
        <hr className="line" />
        <PrismicLink field={menuLink.link}>
          <PrismicText field={menuLink.label} />
        </PrismicLink>
      </li>
    );
  } else {

    return (
      <li>
        <PrismicLink field={menuLink.link}>
          <PrismicText field={menuLink.label} />
        </PrismicLink>
      </li>   
    );
  }
};

/**
 * Site header/nav component
 */
export const Header = ({ menuDoc }) => {
  
  if (menuDoc) {
    const menuItems = [];
    menuDoc.data.menu_links.map((menuLink) => {
        menuItems.push(menuLink)
      })
    return (
      <header className="site-header">
        <PrismicLink href="/">
          <div className="logo">
          <img src="../windmillslamlogo-new-no-text.svg"></img>
            <span>Windmill Slam Games</span>
          </div>
        </PrismicLink>
        <nav>
          <ul>
            {menuItems.map((menuLink) => (
              <MenuLink menuLink={menuLink} key={menuLink.link.id} />
            ))}
          </ul>
        </nav>
      </header>
    );
  }

  return null;
};
