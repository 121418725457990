import { PrismicText, PrismicLink } from "@prismicio/react";
import { useSinglePrismicDocument } from "@prismicio/react";

/**
 * Menu link component
 */
const MenuLink = ({ menuLink }) => {
  return (
    <li>
      <PrismicLink field={menuLink.footer_link}>
        <PrismicText field={menuLink.footer_link_label} />
      </PrismicLink>
    </li>
  );
};

/**
 * Socials link component
 */
const SocialsLink = ({ socialsLink }) => {
  return (
    <li>
      <PrismicLink field={socialsLink.socials_link}>
        <i className={"socials-icon " + "socials-" + socialsLink.socials_type.toLowerCase()}>
        </i>
      </PrismicLink>
    </li>
  );
};

/**
 * Site footer component
 */
export const Footer = () => {
  const [footer, footerState] = useSinglePrismicDocument("footer");

  if (footer) {
    return(
      <footer>
        <div className="footer-column socials-column">
        <ul className="socials-menu">
              {footer.data.socials_links.map((socialsLink, index) => (
                <SocialsLink socialsLink={socialsLink} key={socialsLink.socials_link.id ?? ("weblink_" + index)} />
              ))}
            </ul>
        </div>
        <div className="footer-column menu-column">
            <PrismicText field={footer.data.title} />
          <div className="footer-menu">
            <ul>
              {footer.data.footer_links.map((menuLink, index) => (
                <MenuLink menuLink={menuLink} key={menuLink.footer_link.id ?? ("weblink_" + index)} />
              ))}
            </ul>
          </div>
        </div>
      </footer>
    )
  } else {
  return (
  <footer>
    <p>
      Published with &nbsp;
      <a href="https://prismic.io" target="_blank" rel="noopener noreferrer">
        Prismic
      </a>
      <br />
      <a href="https://prismic.io" target="_blank" rel="noopener noreferrer">
        <img
          className="footer-logo"
          src="/images/logo-prismic.svg"
          alt="Gray Prismic logo"
        />
      </a>
    </p>
    </footer>
  )
};
};
