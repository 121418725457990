import { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  SliceZone,
  usePrismicDocumentByUID,
  useSinglePrismicDocument,
} from "@prismicio/react";

import { components } from "../slices";
import { Layout } from "../components/Layout";
import { NotFound } from "./NotFound";

/**
 * Website blog article component
 */
export const BlogArticlePage = () => {
  const { uid } = useParams();

  const [blogArticle, blogArticleState] = usePrismicDocumentByUID("blog_article", uid);
  const [menu, menuState] = usePrismicDocumentByUID("menu", "navbar_menu");

  const notFound = blogArticleState.state === "failed" || menuState.state === "failed";

  useEffect(() => {
    if (blogArticleState.state === "failed") {
      console.warn(
        "Blog article was not found. Make sure it exists in your Prismic repository"
      );
    }
  }, []);

  // Return the article if a document was retrieved from Prismic
  if (blogArticle && menu) {
    return (
      <Layout wrapperClass={uid + " " + "blog-article"} menuDoc={menu}>
        <div className="back-to-blog"><Link to={"/blog"}>« Back to Blog</Link></div>    
        <SliceZone slices={blogArticle.data.body} components={components} />
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
