import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  SliceZone,
  usePrismicDocumentByUID,
  PrismicText,
  PrismicRichText,
} from "@prismicio/react";

import { components } from "../slices";
import { Layout } from "../components/Layout";
import { NotFound } from "./NotFound";
import { ProjectButton } from "../components/ProjectButton";

/**
 * Website projectPage component
 */
export const ProjectPage = () => {
  const { uid } = useParams();

  const [projectPage, projectPageState] = usePrismicDocumentByUID("project_page", uid);
  const [menu, menuState] = usePrismicDocumentByUID("menu", "navbar_menu");

  const notFound = projectPageState.state === "failed" || menuState.state === "failed";

  useEffect(() => {
    if (projectPageState.state === "failed") {
      console.warn(
        "Page document was not found. Make sure it exists in your Prismic repository"
      );
    }
  }, []);

  // Return the page if a document was retrieved from Prismic
  if (projectPage && menu) {
    return (
      <Layout wrapperClass="project-page" menuDoc={menu}>
        <div className="project-main container">
          <div className="project-main-image"
               style={{
                backgroundImage: `url(${projectPage.data.project_hero_image.url})`,
              }}
          ></div>
          <div className="project-main-description">
            <h1><PrismicText field={projectPage.data.project_title} /></h1>
            <p><PrismicRichText field={projectPage.data.project_short_description} /></p>
            <div className="project-main-buttons">
                  {projectPage.data.project_links.map((projectLink, index) => (
                  <ProjectButton key={index} projectLink={projectLink} />
                  ))}
            </div>
          </div>
        </div>
        <SliceZone slices={projectPage.data.page_content} components={components} />
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
