import { useEffect } from "react";
import { PrismicText, usePrismicDocumentByUID, useAllPrismicDocumentsByType } from "@prismicio/react";

import { components } from "../slices";
import { Layout } from "../components/Layout";
import { HomepageBanner } from "../components/HomepageBanner";
import { NotFound } from "./NotFound";

/**
 * Blog article item component
 */
const BlogArticleItem = ({ article, number, index }) => {
    
    const position = (index % 2) ? "right" : "left";

    return (
      <a href={article.url} className="blog-article-box">
        <div className={"blog-article-image image-" + position }
        // style={{backgroundImage: `url(${article.data.blog_article_image.url})`,}}
        >
        </div>
        <div className="blog-article-text">
          <h3>#{number} <PrismicText field={article.data.title} /></h3>
          <p><PrismicText field={article.data.summary} /></p>
        </div>
      </a>
    );
  };

/**
 * Website blog component
 */
export const BlogPage = () => {
  const [menu, menuState] = usePrismicDocumentByUID("menu", "navbar_menu");
  const [blogArticles, blogArticlesState] = useAllPrismicDocumentsByType("blog_article");

  const notFound = blogArticlesState.state === "failed" || menuState.state === "failed";

  useEffect(() => {
    if (blogArticlesState.state === "failed") {
      console.warn(
        "Blog article overview was not found."
      );
    }
  }, [blogArticlesState.state]);

  // Return the page if a document was retrieved from Prismic
  if (blogArticles && menu) {
    console.log(blogArticles)
    let sortedBlogArticles = blogArticles.sort((a,b) => {
      return new Date(a.data.date) - new Date(b.data.date)
    })

    const sortedBlogArticlesReverse = sortedBlogArticles.reverse();

    return (
      <Layout wrapperClass="blog" menuDoc={menu}>
        <div className="container blog-container">
            {sortedBlogArticlesReverse.map((article, index) => (
                <BlogArticleItem key={index} article={article} number={sortedBlogArticlesReverse.length - index} index={index} />
            ))}
        </div>
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
