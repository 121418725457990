import { useState, useEffect, useRef} from "react";

const useParalaxImage = 
    setScrollPosition => {
        const handleScroll = () =>
            setScrollPosition(window.scrollY);

    useEffect(() => {
        window.addEventListener(
            "scroll",
            handleScroll,
            { passive: true}
        );

        return () =>
        window.removeEventListener(
            "scroll", handleScroll);
    }, []);
}


/**
 * Paralax image slice component
 */
export const ParalaxImage = ({ slice }) => {
    const[scrollPosition, setScrollPosition] = useState(0);

    useParalaxImage(setScrollPosition);

    const windowHeight = window.innerHeight;

    // image style is defined as 60vh
    const imageHeight = 0.6 * windowHeight;

    const paralaxRef = useRef();

    const [paralaxOffset, setparalaxOffset] = useState();

    const getPosition = () => {
        const paralaxOffset = paralaxRef.current.offsetTop;
        setparalaxOffset(paralaxOffset);
    };

    // Get the position of the paralax image in the beginning
    useEffect(() => {
        getPosition();
    }, []);

    // Re-calculate position Y of the paralax image when the window is resized by the user
    useEffect(() => {
        document.addEventListener("DOMContentLoaded", function() {
            window.addEventListener("resize", getPosition);
        });
    }, []);

    return (
        <section className="content-section">
            <div ref={paralaxRef} className="paralax-image" 
                style={{
                    backgroundImage: `url(${slice.primary.image_link.url})`,
                    backgroundPositionY: `${(scrollPosition + windowHeight - paralaxOffset) / imageHeight * 30}%`
                    }}>
            </div>
        </section>
    );
}
