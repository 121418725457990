import { PrismicText, PrismicRichText } from "@prismicio/react";
import { ProjectButton } from "./ProjectButton";

/**
 * Project item component
 */
const ProjectItem = ({ project }) => {
  return (
    <a href={project.url} className="other-projects-box">
      <div className="other-projects-image" style={{
        backgroundImage: `url(${project.data.project_hero_image.url})`,
      }}>
      </div>
      <div className="other-projects-title">
        <PrismicText field={project.data.project_title} />
        <p className="gallery-link"></p>
      </div>
    </a>
  );
};

/**
 * Homepage banner component
 */
export const HomepageBanner = ({latestProject, otherProjects }) => (
  <section
    className="homepage-banner"
  >
    <div className="banner-content container">
        <div className="latest-project-container"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(${latestProject.data.project_hero_image.url})`,
                      }}>
          <div className="latest-project-banner">
            <div className="latest-project-content">
              <h2 className="latest-project-title"><PrismicText field={latestProject.data.project_title} /></h2>
              <p className="latest-project-text"><PrismicRichText field={latestProject.data.project_short_description} /></p>
              <div className="latest-project-buttons">
                {latestProject.data.project_links.map((projectLink, index) => (
                <ProjectButton key={index} projectLink={projectLink} />
                ))}
                <a className="project-page-link" href={latestProject.url}>
                  <span className="project-page-link-span">View Project</span>
                </a>
              </div>
              </div>
          </div>
      </div>
      {/* <div className="banner-right latest-blog-post">
        <h1>Latest Blog Post</h1>
      </div> */}
      <div className="other-projects-container">
        <h1>Other Projects</h1>
        <div className="other-projects">
          {otherProjects.map((project, index) => (
            <ProjectItem key={index} project={project} />
          ))}
        </div>
      </div>
    </div>
  </section>
);
